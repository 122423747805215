import React from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { useCategories } from '../services/services';
import { useNavigate } from "react-router-dom";

const DogFAQComponent = ({ category, handleService,data }) => {
  const categories = useCategories();
  const navigate = useNavigate();

  const handleClick = (val) => {
    handleService(val, navigate);
  };

  return (
    <div className="faq">
      <div className="FAQHeader">
        <p>SOME OF THE</p>
        <h2 id="faqsHead">Frequently Asked Questions.</h2>
      </div>
      <Container>
        <div className="faqs">
          <Accordion defaultActiveKey="0" flush>
            {
              data?.faqs?.map((faq,index)=>(
                <Accordion.Item eventKey={index}>
              <Accordion.Header><h3 className='accordian-h3'>{faq.ques}</h3></Accordion.Header>
              <Accordion.Body>
               <p>{faq.ans}</p>
              </Accordion.Body>
            </Accordion.Item>
          
              ))
            }
            
          </Accordion>
        </div>
      </Container>
    </div>
  );
};

export default DogFAQComponent;
