import sanityClient from "@sanity/client";
import axios from "axios";

// Sanity client configuration
const client = sanityClient({
  projectId: "dykyk4sq",
  dataset: "production",
  apiVersion: "2023-11-22",
  useCdn: false,
  token:
    "skq2nebrqtZB25w20x3NG9rs92OBQy5oOoNQ6iwbvsxnwMJTT65a1wtmzv8Ur7sYQ3wsFymN4nrybxNJ96fjYcmJMXtCCLkDJcOIU3zjSXhQBFZtX1c4YRGBGg9D01noig8zLFiNmwYPxTq5PRDEQmcpdJZIezoGpaID1Pkpis4wIFjqPy5M",
});

/**
 * Fetch content by keyword from Sanity using GET request
 * @param {string} keyword - The keyword to filter content by
 * @returns {Promise<Array>} - Array of matching content documents
 */
const fetchContentByKeyword = async ({ location, breed }) => {
  try {
    // Sanity API endpoint for querying
    let query = `*[_type == "pageContent" && petLocation == "${location}"]{
      _id,
      petLocation,
      location,
      metaTitle,
      metaDesc,
      mainHead,
      mainContent,
      tableHead,
      tableColumnHead1,
      tableColumnHead2,
      tableContent,
      faqs,
      contentPara1Head,
      contentPara1Cont,
      puppyPricingGuid,
      factorAffecting,
      topTenBreedsCont,
      topTenBreeds,
      conclusion,
      locationLinks,
      createdDate
    }`;
    if (breed !== "") {
      query = `*[_type == "breedSpecific" && petLocation == "${location}" && petBreed == "${breed}"]{
  petLocation,
  petBreed,
  breed,
  location,
  metaTitle,
  metaDesc,
  mainHead,
  mainContent,
  tableHead,
  tableColumnHead1,
  tableColumnHead2,
  tableContent[]{
    col1, 
    col2, 
    url
  },
  faqs[]{
    ques, 
    ans
  },
  contentPara1Head,
  contentPara1Cont,
  puppyPricingGuid[]{
    breed, 
    price, 
    description
  },
  factorAffecting[]{
    head, 
    content
  },
  topTenBreedsCont,
  topTenBreeds[]{
    breed, 
    price, 
    traits, 
    bestFor
  },

  // Expanded maintainance, training, and caringTips fields based on sectionContent schema
  maintainance{
    title,
    description,
    sections[]{
      heading,
      content[]{
        ...  // Retrieves all block content for each section
      }
    }
  },
  training{
    title,
    description,
    sections[]{
      heading,
      content[]{
        ...
      }
    }
  },
  caringTips{
    title,
    description,
    sections[]{
      heading,
      content[]{
        ...
      }
    }
  },

  pricingBasedCoatColor{
    breed, 
    price, 
    description
  },
  pricingBasedCoatColorHead,
  pricingBasedCoatColorContent,
  pricingChampionHead,
  pricingChampionContent,
  pricingChampion[]{
    head, 
    content
  },
  contentPara2Head,
  contentPara2Cont,
  breedIntroHead,
  breedIntro[]{
    head, 
    content
  },
  conclusion,
  locationLinks[]{
    linkName, 
    url
  },
  createdDate
}`;
    }

    // URL encode the query for safe usage in URL
    const encodedQuery = encodeURIComponent(query);

    // Make GET request with the query and token
    const result = await axios.get(
      `https://dykyk4sq.api.sanity.io/v2023-11-22/data/query/production?query=${encodedQuery}`,
      {
        headers: {
          Authorization: `Bearer skq2nebrqtZB25w20x3NG9rs92OBQy5oOoNQ6iwbvsxnwMJTT65a1wtmzv8Ur7sYQ3wsFymN4nrybxNJ96fjYcmJMXtCCLkDJcOIU3zjSXhQBFZtX1c4YRGBGg9D01noig8zLFiNmwYPxTq5PRDEQmcpdJZIezoGpaID1Pkpis4wIFjqPy5M`,
        },
      }
    );

    // Check if no data is returned
    if (!result.data || result.data.result.length === 0) {
      console.warn(`No content found for the selected filters`);
    }

    return result.data.result; // Return the result array
  } catch (error) {
    console.error(`Failed to fetch content for the selected filters`, error);
    throw error;
  }
};

export default fetchContentByKeyword;
