import React, { useState} from "react";
import Container from 'react-bootstrap/Container';
import { Button, Form } from "react-bootstrap";

const NotFound = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
 
  const handleSubmitQuery = (e) => {
    e.preventDefault();

    const encodedMessage = `Hi! I need help finding a pet. Email: ${email}, Message: ${message}`;
    const whatsappLink = `https://api.whatsapp.com/send/?phone=%2B919004044078&text=${encodeURIComponent(
      encodedMessage
    )}&type=phone_number&app_absent=0`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <>


  <Container  className="container">
  <div className="GoodFurs-row">
        <div className="GoodFurs5 GoodFurs-message">
        <h2 className="fw-bold">Don't worry! Your furry friend is just around the corner 🐾</h2>
        <p>
            Sometimes the best companions take a little longer to find. If you
            need help or have questions, we're here for you!
          </p>
  
          {/* Form Section */}
          <Form onSubmit={handleSubmitQuery}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
  
            <Form.Group controlId="formMessage">
              <Form.Label>Your Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Describe your inquiry"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>
  
            <Button variant="primary" type="submit" className="mt-3">
              Contact Us
            </Button>
          </Form>
        </div>
      </div>
     
    
   
   


  
    </Container>

     
    </>
  );
}

export default NotFound;
