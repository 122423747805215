import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";

const FilterComponent = ({ onFilter, petType }) => {
  const [location, setLocation] = useState("");
  const [price, setPrice] = useState("");
  const [breed, setBreed] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [color, setColor] = useState("");
  const [coatType, setCoatType] = useState("");
  const [size, setSize] = useState("");
  const [healthAssurance, setHealthAssurance] = useState("");
  const [temperament, setTemperament] = useState("");
  const [showFilters, setShowFilters] = useState(false);

  const handleFilter = () => {
    const filters = {
      location,
      price,
      breed,
      gender,
      age: age ? parseInt(age, 10) : "",
      color,
      size,
      healthAssurance,
      coatType,
      temperament,
    };
    console.log("Filters sent:", filters);
    onFilter(filters);
  };

  const priceOptions = [
    { label: "Select Price Range", value: "" },
    { label: "Less than 10,000", value: "<10000" },
    { label: "10,000 - 20,000", value: "10000-20000" },
    { label: "20,000 - 30,000", value: "20000-30000" },
    { label: "30,000 - 40,000", value: "30000-40000" },
    { label: "40,000 - 50,000", value: "40000-50000" },
    { label: "50,000 - 60,000", value: "50000-60000" },
    { label: "60,000 - 70,000", value: "60000-70000" },
    { label: "70,000 - 80,000", value: "70000-80000" },
    { label: "80,000 - 90,000", value: "80000-90000" },
    { label: "90,000 - 1 Lakh", value: "90000-100000" },
    { label: "More than 1 Lakh", value: ">100000" },
  ];

  const locations = [
    { label: "Select Location", value: "india" },
    { label: "Mumbai", value: "mumbai" },
    { label: "Bengaluru", value: "bengaluru" },
    { label: "Delhi", value: "delhi" },
    { label: "Gurugram", value: "gurugram" },
    { label: "Pune", value: "pune" },
    { label: "Hyderabad", value: "hyderabad" },
    { label: "Chennai", value: "chennai" },
    { label: "Kolkata", value: "kolkata" },
    { label: "Ahmedabad", value: "ahmedabad" },
    { label: "Surat", value: "surat" },
    { label: "Jaipur", value: "jaipur" },
    { label: "Lucknow", value: "lucknow" },
    { label: "Chandigarh", value: "chandigarh" },
    { label: "Kanpur", value: "kanpur" },
    { label: "Varanasi", value: "varanasi" },
    { label: "Agra", value: "agra" },
    { label: "Dehradun", value: "dehradun" },
    { label: "Amritsar", value: "amritsar" },
    { label: "Ghaziabad", value: "ghaziabad" },
    { label: "Faridabad", value: "faridabad" },
    { label: "Noida", value: "noida" },
    { label: "Patiala", value: "patiala" },
    { label: "Jodhpur", value: "jodhpur" },
    { label: "Coimbatore", value: "coimbatore" },
    { label: "Visakhapatnam", value: "visakhapatnam" },
    { label: "Vijayawada", value: "vijayawada" },
    { label: "Mysore", value: "mysore" },
    { label: "Madurai", value: "madurai" },
    { label: "Mangalore", value: "mangalore" },
    { label: "Trichy (Tiruchirappalli)", value: "trichy" },
    { label: "Warangal", value: "warangal" },
    { label: "Hubli-Dharwad", value: "hubli_dharwad" },
    { label: "Salem", value: "salem" },
    { label: "Vadodara", value: "vadodara" },
    { label: "Nagpur", value: "nagpur" },
    { label: "Nashik", value: "nashik" },
    { label: "Aurangabad", value: "aurangabad" },
    { label: "Rajkot", value: "rajkot" },
    { label: "Bhopal", value: "bhopal" },
    { label: "Indore", value: "indore" },
    { label: "Udaipur", value: "udaipur" },
    { label: "Gandhinagar", value: "gandhinagar" },
    { label: "Bhubaneswar", value: "bhubaneswar" },
    { label: "Ranchi", value: "ranchi" },
    { label: "Patna", value: "patna" },
    { label: "Jamshedpur", value: "jamshedpur" },
    { label: "Siliguri", value: "siliguri" },
    { label: "Dhanbad", value: "dhanbad" },
    { label: "Guwahati", value: "guwahati" },
    { label: "Shillong", value: "shillong" },
    { label: "Ambala", value: "ambala" },
    { label: "Bhiwani", value: "bhiwani" },
    { label: "Charkhi Dadri", value: "charkhi_dadri" },
    { label: "Fatehabad", value: "fatehabad" },
    { label: "Hisar", value: "hisar" },
    { label: "Jhajjar", value: "jhajjar" },
    { label: "Jind", value: "jind" },
    { label: "Kaithal", value: "kaithal" },
    { label: "Karnal", value: "karnal" },
    { label: "Kurukshetra", value: "kurukshetra" },
    { label: "Mahendragarh", value: "mahendragarh" },
    { label: "Nuh", value: "nuh" },
    { label: "Palwal", value: "palwal" },
    { label: "Panchkula", value: "panchkula" },
    { label: "Panipat", value: "panipat" },
    { label: "Rewari", value: "rewari" },
    { label: "Rohtak", value: "rohtak" },
    { label: "Sirsa", value: "sirsa" },
    { label: "Sonipat", value: "sonipat" },
    { label: "Yamunanagar", value: "yamunanagar" },
    { label: "Barnala", value: "barnala" },
    { label: "Bathinda", value: "bathinda" },
    { label: "Faridkot", value: "faridkot" },
    { label: "Fatehgarh Sahib", value: "fatehgarh_sahib" },
    { label: "Firozpur", value: "firozpur" },
    { label: "Gurdaspur", value: "gurdaspur" },
    { label: "Hoshiarpur", value: "hoshiarpur" },
    { label: "Jalandhar", value: "jalandhar" },
    { label: "Kapurthala", value: "kapurthala" },
    { label: "Ludhiana", value: "ludhiana" },
    { label: "Mansa", value: "mansa" },
    { label: "Moga", value: "moga" },
    { label: "Muktsar", value: "muktsar" },
    { label: "Rupnagar", value: "rupnagar" },
    { label: "Sangrur", value: "sangrur" },
    { label: "Sri Muktsar Sahib", value: "sri_muktsar_sahib" },
    { label: "Tarn Taran", value: "tarn_taran" },
    { label: "Mohali", value: "mohali" },
    { label: "Pathankot", value: "pathankot" },
  ];

  const petGender = [
    { label: "Select Pet Gender", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];

  const petAge = [
    { label: "Select Pet Age", value: "" },
    { label: "4 weeks", value: "4" },
    { label: "8 weeks", value: "8" },
    { label: "12 weeks", value: "12" },
    { label: "16 weeks", value: "16" },
    { label: "20 weeks", value: "20" },
    { label: "24 weeks", value: "24" },
    { label: "28 weeks", value: "28" },
    { label: "32 weeks", value: "32" },
    { label: "36 weeks", value: "36" },
    { label: "40 weeks", value: "40" },
    { label: "44 weeks", value: "44" },
    { label: "48 weeks", value: "48" },
    { label: "52 weeks", value: "52" }, // 1 year
    { label: "56 weeks", value: "56" },
    { label: "60 weeks", value: "60" },
    { label: "64 weeks", value: "64" },
    { label: "68 weeks", value: "68" },
    { label: "72 weeks", value: "72" }, // 1.5 years
    { label: "76 weeks", value: "76" },
    { label: "80 weeks", value: "80" },
    { label: "84 weeks", value: "84" },
    { label: "88 weeks", value: "88" },
    { label: "92 weeks", value: "92" },
    { label: "96 weeks", value: "96" }, // 2 years
    { label: "104 weeks", value: "104" },
    { label: "112 weeks", value: "112" },
    { label: "120 weeks", value: "120" },
    { label: "130 weeks", value: "130" },
    { label: "140 weeks", value: "140" },
    { label: "160 weeks", value: "160" }, // 3 years
    { label: "180 weeks", value: "180" },
    { label: "200 weeks", value: "200" },
    { label: "240 weeks", value: "240" }, // 5 years
  ];

  const dogBreeds = [
    { label: "Select Dog Breed", value: "" },
    { label: "Labrador Retriever", value: "labrador_retriever" },
    { label: "German Shepherd", value: "german_shepherd" },
    { label: "Golden Retriever", value: "golden_retriever" },
    { label: "Beagle", value: "beagle" },
    { label: "Bulldog", value: "bulldog" },
    { label: "Poodle", value: "poodle" },
    { label: "Dachshund", value: "dachshund" },
    { label: "Boxer", value: "boxer" },
    { label: "Rottweiler", value: "rottweiler" },
    { label: "Chihuahua", value: "chihuahua" },
    { label: "Shih Tzu", value: "shih_tzu" },
    { label: "Pug", value: "pug" },
    { label: "Cocker Spaniel", value: "cocker_spaniel" },
    { label: "Dalmatian", value: "dalmatian" },
    { label: "Husky", value: "siberian_husky" },
    { label: "Doberman Pinscher", value: "doberman_pinscher" },
    { label: "Pomeranian", value: "pomeranian" },
    { label: "Border Collie", value: "border_collie" },
    { label: "Great Dane", value: "great_dane" },
    { label: "Bull Terrier", value: "bull_terrier" },
    { label: "Saint Bernard", value: "saint_bernard" },
    { label: "Schnauzer", value: "schnauzer" },
    { label: "Mastiff", value: "mastiff" },
    { label: "Akita", value: "akita" },
    { label: "Shar Pei", value: "shar_pei" },
  ];

  const catBreeds = [
    { label: "Select Cat Breed", value: "" },
    { label: "Persian", value: "persian" },
    { label: "Siamese", value: "siamese" },
    { label: "Maine Coon", value: "maine_coon" },
    { label: "Ragdoll", value: "ragdoll" },
    { label: "Bengal", value: "bengal" },
    { label: "Sphynx", value: "sphynx" },
    { label: "British Shorthair", value: "british_shorthair" },
    { label: "Scottish Fold", value: "scottish_fold" },
    { label: "Abyssinian", value: "abyssinian" },
    { label: "Birman", value: "birman" },
    { label: "Exotic Shorthair", value: "exotic_shorthair" },
    { label: "Turkish Van", value: "turkish_van" },
    { label: "Russian Blue", value: "russian_blue" },
    { label: "Egyptian Mau", value: "egyptian_mau" },
    { label: "Himalayan", value: "himalayan" },
    { label: "American Shorthair", value: "american_shorthair" },
    { label: "Norwegian Forest Cat", value: "norwegian_forest" },
    { label: "Savannah", value: "savannah" },
    { label: "Tonkinese", value: "tonkinese" },
    { label: "Manx", value: "manx" },
    { label: "Cornish Rex", value: "cornish_rex" },
    { label: "Devon Rex", value: "devon_rex" },
  ];

  const sizes = [
    { label: "Select Size", value: "" },
    { label: "Small", value: "small" },
    { label: "Medium", value: "medium" },
    { label: "Large", value: "large" },
  ];

  const colors = [
    { label: "Select color", value: "" },
    { label: "White", value: "white" },
    { label: "Brown", value: "brown" },
    { label: "Black", value: "black" },
  ];

  const coatTypes = [
    { label: "Select Coat Type", value: "" },
    { label: "Short", value: "short" },
    { label: "Medium", value: "medium" },
    { label: "Long", value: "long" },
    { label: "Hypoallergenic", value: "hypoallergenic" },
  ];

  const temperaments = [
    { label: "Select Temperaments", value: "" },
    { label: "Friendly", value: "friendly" },
    { label: "Active", value: "active" },
    { label: "Calm", value: "calm" },
    { label: "Independent", value: "independent" },
  ];

  const healthAssurances = [
    { label: "Select Health Assurance", value: "" },
    { label: "Vaccinated", value: "vaccinated" },
    { label: "Health Certificate", value: "health_certificate" },
    { label: "Dewormed", value: "dewormed" },
  ];
  return (
    <div className="centerDiv">
      <div className="filter">
        <label>
          Breed:
          <select value={breed} onChange={(e) => setBreed(e.target.value)}>
            {petType === "cat"
              ? catBreeds.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))
              : dogBreeds.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
          </select>
        </label>

        <label>
          Location
          <select
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          >
            {locations.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </label>
        
        <label>
          Gender
          <select value={gender} onChange={(e) => setGender(e.target.value)}>
            {petGender.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </label>

        {showFilters && (
          <>
            <label>
              Age (1 to 20 years):
              <select value={age} onChange={(e) => setAge(e.target.value)}>
                {petAge.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Price Range:
              <select value={price} onChange={(e) => setPrice(e.target.value)}>
                {priceOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Coat Type:
              <select
                value={coatType}
                onChange={(e) => setCoatType(e.target.value)}
              >
                {coatTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Color:
              <select value={color} onChange={(e) => setColor(e.target.value)}>
                {colors.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Size:
              <select value={size} onChange={(e) => setSize(e.target.value)}>
                {sizes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Temperament:
              <select
                value={temperament}
                onChange={(e) => setTemperament(e.target.value)}
              >
                {temperaments.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>

            <label>
              Health Assurance:
              <select
                value={healthAssurance}
                onChange={(e) => setHealthAssurance(e.target.value)}
              >
                {healthAssurances.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </label>
          </>
        )}
        <button
          id="showMoreBtn"
          type="button"
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? "Show Less Filters" : "Show More Filters"}
        </button>
        <button onClick={handleFilter}>Search</button>
      </div>
    </div>
  );
};

export default FilterComponent;
