import React, { useState, useEffect } from "react";
import { Container, Table, Row, Col } from "react-bootstrap";
import PetAdoptionComp from "../../../component/Service";
import axios from "axios";
import { ShimmerPostItem } from "react-shimmer-effects";
import FilterComponent from "../../../component/FilterComponent";
import Testimonials from "../../../component/Testimonials";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import DogFAQComponent from "../../../component/DogFAQComponent";
import DogContent from "../../../component/DogContent";
import NotFound from "../../../component/NotFound";
import fetchContentByKeyword from "../../../FetchSanity";

const DogType = () => {
  const [petData, setPetData] = useState([]);
  const [pageData, setPageData] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [filters, setFilters] = useState({
    location: "",
    breed: "",
    priceRange: "",
    gender: "",
    age: "",
    coatType: "",
    color: "",
    size: "",
    temperament: "",
    healthAssurance: "",
  });

  useEffect(() => {
    const path = location.pathname.split("+");

    const locationFilter = path[2]?.trim();

    const newFilters = {
      location: locationFilter || "",
      breed: path[3]?.trim() || "",
      gender: path[4]?.trim() || "",
      priceRange: path[5]?.trim() || "",
      color: path[6]?.trim() || "",
      age: path[7]?.trim() || "",
      coatType: path[8]?.trim() || "",
      size: path[9]?.trim() || "",
      temperament: path[10]?.trim() || "",
      healthAssurance: path[11]?.trim() || "",
    };

    setFilters(newFilters);

    getContentData(newFilters);
    getData(newFilters);
  }, [location]);

  const getContentData = async (filters) => {
    setLoading(true);
    try {
      const data = await fetchContentByKeyword({location:filters.location,breed:filters.breed});
      setPageData(data[0]);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getData = (filters) => {
    setLoading(true);

    const params = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });
    const url = `${
      process.env.REACT_APP_HOST
    }adoption?${params.toString()}&petType=dog`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.status === true) {
          setPetData(response.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  };

  const placeholderItems = Array.from({ length: 4 });

  const handleFilter = (newFilters) => {
    if(!newFilters.location){
      newFilters.location='india';
    }
    const filters = Object.values(newFilters)
      .map((filter) => filter?.trim())
      .filter(Boolean);

    let filterPath = filters.join("+").replace(/\++/g, "+");

    filterPath = filterPath.replace(/\+$/, "");
    const url = `/category/buy-dogs-online+in+${filterPath}`;
    window.location.href = url;
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  const truncatedText = `
    Discover a variety of adorable puppies available for sale across ${pageData?.location}...
  `;

  return (
    <Container>
      <Helmet>
        <title>{pageData?.metaTitle}</title>
        <meta name="description" content={pageData?.metaDesc} />
      </Helmet>

      <div className="top Dog-container services service-head" />
      <Row className="mb-4">
        <Col>
        {
          pageData?.mainHead?(<h1 className="text-center fw-bold">
           {pageData?.mainHead}
          </h1>
          ):(<h1 className="text-center fw-bold">
            Buy Dogs Online in {pageData?.location}: Explore Puppy Prices and{" "}
            <br />
            Available Breeds Good Furs
          </h1>
          )
        }
          <p className="text-center fs-5 text-muted">
            {isExpanded ? pageData?.mainContent : truncatedText}
            <span
              className="text-primary ms-2"
              style={{ cursor: "pointer" }}
              onClick={toggleReadMore}
            >
              {isExpanded ? "Read Less" : "Read More"}
            </span>
          </p>
        </Col>
      </Row>

      {/* Top 5 Popular Puppies Breed Table */}
      {isExpanded && (
        <Row className="mb-5 justify-content-center">
          <Col md={8} lg={6}>
            <h2 className="text-center fw-bold mb-3">{pageData?.tableHead}</h2>
            <Table bordered hover className="text-center table-sm">
              <thead>
                <tr>
                  <th style={{ width: "60%" }}>{pageData?.tableColumnHead1}</th>
                  <th style={{ width: "40%" }}>{pageData?.tableColumnHead2}</th>
                </tr>
              </thead>
              <tbody>
                {pageData?.tableContent?.map((tb) => (
                  <tr>
                    <td>
                      <a href="#" className="text-decoration-none">
                        {tb.col1}
                      </a>
                    </td>
                    <td>{tb.col2}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      <div className="justify-content-center">
        <FilterComponent petType={"dog"} onFilter={handleFilter} />
      </div>

      <Container fluid className="container lg-ds">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div className="services service-head">
              <h2>Type dog</h2>
              <p>Find Your Buddy!</p>
              <h4 id="hide">Find Your Buddy!</h4>
            </div>
          </Col>
        </Row>
      </Container>

      {!loading && petData.length === 0 && <NotFound />}

      <div className="adoption">
        {loading && (
          <div className="category-shimmer">
            {placeholderItems.map((_, index) => (
              <ShimmerPostItem
                key={index}
                card
                title
                cta
                imageType="thumbnail"
                imageWidth={50}
                imageHeight={50}
                contentCenter
              />
            ))}
          </div>
        )}

        {!loading && petData.length > 0 && (
          <>
            {petData.map((pet, index) => (
              <PetAdoptionComp
                key={index}
                petImage={pet.serviceImage}
                priceRange={`${pet.servicePrice}`}
                petName={pet.serviceName}
                address={pet.petBreed}
                gender={pet.petGender}
                dateOfBirth={pet.dateOfBirth}
                btnName="Whatsapp"
                petUserName={pet.petName}
                serviceCity={pet.serviceCity}
                serviceId={pet._id}
                petBreed={pet.petBreed}
              />
            ))}
          </>
        )}
      </div>

      <Testimonials />
      <DogFAQComponent data={pageData} />
      <DogContent data={pageData} />
    </Container>
  );
};

export default DogType;
