import React from 'react'
import NotFound from '../../component/NotFound'

const NotFound404 = () => {
  return (
    <div className='notFoundContainer'>

        <NotFound />
    </div>
  )
}

export default NotFound404