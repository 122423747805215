import React from "react";
import Logo from "../../assets/media/logo.png";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import ScrollToTopLink from "./Scroll";
import { FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="footer-bg">
        <div className="container footer">
          <div className="footer-section">
            <div className="footer-logo">
              <img src={Logo} alt="Logo" />
              <div className="socials">
                <ScrollToTopLink to="https://www.instagram.com/good_furs/profilecard/?igsh=MnM3N3FjZG40ajFq">
                  <FaInstagram />
                </ScrollToTopLink>
                <ScrollToTopLink to="https://www.facebook.com/profile.php?id=61566565102528&mibextid=LQQJ4d">
                  <FaFacebookF />
                </ScrollToTopLink>
                <ScrollToTopLink to="https://www.linkedin.com/company/goodfurs/">
                  <FaLinkedinIn />
                </ScrollToTopLink>
              </div>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-card">
              <h5>Company</h5>
              <ul>
                <li>
                  <ScrollToTopLink to="/about-us">About us</ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink to="/contact-us">Contact us</ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink to="/privacy-policy">
                    Privacy Policy
                  </ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink to="/terms-condition">
                    Terms & Condition
                  </ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink to="/refund-policy">
                    Refund Policy
                  </ScrollToTopLink>
                </li>
                <li>
                  <a
                    href="/sitemap.xml"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Sitemap
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-card">
              <h5>Resources</h5>
              <ul>
                <li>
                  <ScrollToTopLink
                    to="https://api.whatsapp.com/send/?phone=%2B919004044078&text&type=phone_number&app_absent=0"
                    target="_blank"
                  >
                    Help Center
                  </ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink to="" target="_blank">
                    Join Pet Community
                  </ScrollToTopLink>
                </li>
                <li>
                  <ScrollToTopLink
                    to="https://api.whatsapp.com/send/?phone=%2B919004044078&text&type=phone_number&app_absent=0"
                    target="_blank"
                  >
                    Feedback
                  </ScrollToTopLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_end">
        <div className="container end">
          <p>© 2023-2024 Copyright</p>
          <p>Good Furs Private Limited</p>
          <p>All Rights Reserved</p>
          <p>
            Business Developed By{" "}
            <a href="https://rapydlaunch.com" target="_blank">
              RapydLaunch
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
