// src/router.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/authScreens/Login";
import Privacy from "../pages/pages/privacy";
import About from "../pages/pages/about";
import Terms from "../pages/pages/terms";
import SearchPage from "../pages/pages/SearchPage";
import PetAdoption from "../pages/pages/Adoption/PetAdoption";
import CatType from "../pages/pages/Adoption/CatType";
import OtherType from "../pages/pages/Adoption/OtherType";
import Thanks from "../pages/pages/Thanks";
import PetDetails from "../pages/pages/Adoption/PetDetails";
import BreedSingle from "../pages/pages/Adoption/BreedCategoiesSingle";
import RefundPolicy from "../pages/pages/RefundPolicy";
import Contact from "../pages/pages/ContactUs";
import DeliveryProcess from "../pages/pages/Delivery";
import DogType from "../pages/pages/Adoption/DogType";
import NotFound404 from "../pages/pages/NotFound404";


function Router() {
  return (
    <Routes>
       <Route path="/" element={<PetAdoption />} />
      <Route path="/login" element={<Login />} />
      <Route path="/search/:keyword" element={<SearchPage />} /> 
      <Route path="/privacy-policy/" element={<Privacy />} />
      <Route path="/about-us/" element={<About />} />
      <Route path="/terms-condition/" element={<Terms />} />
      <Route path="/buy-cats-online+in+india" element={<CatType />} />
      <Route path="/category/:filters" element={<DogType />} />
      <Route path="/buy-dogs-online" element={<DogType />} />
      <Route path="/buy-others-online/:filters" element={<OtherType />} />
      <Route path="*" element={<NotFound404 />} />
      <Route path="/thank-you" element={<Thanks />} />
      <Route path="/pet-sale/:id" element={<PetDetails />} />
      <Route path="/breed/:breedName/:petType" element={<BreedSingle />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/delivery-process" element={<DeliveryProcess />} />
    </Routes>
  );
}

export default Router;
