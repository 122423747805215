import React, { useState, useEffect } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Dog from "../../../assets/media/dog.webp";
import Cat from "../../../assets/media/cat.webp";
import Other from "../../../assets/media/small.webp";
import BreedCard from "../../../component/BreedComp";
import BannerImage from "../../../component/BannerImage";
import Testimonials from "../../../component/Testimonials";
import { ShimmerPostItem, ShimmerSimpleGallery } from "react-shimmer-effects";
import PetAdoptionFaq from "../../../component/PetAdoptionFAQ";
import CitySelectionModal from "../../../modal/CitySelection";
import { Helmet } from "react-helmet-async";
const PetAdoption = () => {
  const metaTags = {
    title: "Buy Healthy Puppies Online from Trusted Breeders | Good Furs",
    description:
      "Buy healthy puppies online from Good Furs. Trusted breeders, no puppy mills, and easy, safe delivery to your doorstep for a perfect new family member.",
  };
  const [petData, setPetData] = useState({
    dogs: [],
    cats: [],
    dogBreeds: [],
    catBreeds: [],
  });
  const [loading, setLoading] = useState({ dogs: false, cats: false });
  const [pageLoading, setPageLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const isLoggedIn = () => !!localStorage.getItem("userToken");

  useEffect(() => {
    const fetchData = async () => {
      await fetchPetData("dog");
      await fetchPetData("cat");
    };

    fetchData();

    setTimeout(() => {
      setPageLoading(false);
    }, 1000);
  }, [navigate]);

  const fetchPetData = async (petType) => {
    setLoading((prev) => ({ ...prev, [petType]: true }));

    try {
      const [adoptionResponse, breedsResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_HOST}adoption?petType=${petType}`),
        axios.get(`${process.env.REACT_APP_HOST}breeds?petType=${petType}`),
      ]);

      if (adoptionResponse.data.status) {
        setPetData((prev) => ({
          ...prev,
          [petType]: adoptionResponse.data.data,
          [petType === "dog" ? "dogBreeds" : "catBreeds"]: Object.entries(
            breedsResponse.data.data[petType]
          ).map(([breedName, breedInfo]) => ({
            name: breedName,
            count: breedInfo.count,
            image: breedInfo.image,
          })),
        }));
      } else {
        setErrorMessage(adoptionResponse.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("An error occurred while fetching data.");
    } finally {
      setLoading((prev) => ({ ...prev, [petType]: false }));
    }
  };

  const handlePetType = (val) => {
    switch (val) {
      case "cat":
        navigate(`/buy-${val}s-online+in+india`);
        break;
      case "dog":
        navigate(`/category/buy-${val}s-online+in+india`);
        break;
      case "other":
        navigate(`/buy-${val}s-online+in+india`);
        break;

      default:
        break;
    }
  };

  const renderBreedCards = (breeds, loading, petType) => {
    if (loading) {
      return Array.from({ length: 20 }).map((_, index) => (
        <ShimmerPostItem key={index} />
      ));
    }
    return breeds.length > 0 ? (
      breeds.map((breed, index) => (
        <BreedCard
          key={index}
          breedImg={breed.image}
          breedName={breed.name}
          breedCount={breed.count}
          petType={petType}
        />
      ))
    ) : (
      <p>No breeds found</p>
    );
  };

  if (pageLoading) {
    return (
      <Container>
        <Row>
          <Col lg={12} style={{ marginTop: "120px" }}>
            <ShimmerSimpleGallery card imageHeight={300} caption />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="description" content={metaTags.description} />
      </Helmet>

      <BannerImage />
      <Container>
        <div className="top" />
        <div className="top" />
        <div className="top" />
        <Row>
          <Col lg={12}>
            <div className="services service-head">
              <h2>Are you</h2>
              <p>Looking for a pet?</p>
              <h4 className="d-none d-lg-block">Looking for a pet?</h4>
            </div>
            <div className="services-dv-new">
              <div
                className="services-list"
                onClick={() => handlePetType("dog")}
              >
                <img src={Dog} alt="Dog" />
              </div>
              <div
                className="services-list"
                onClick={() => handlePetType("cat")}
              >
                <img src={Cat} alt="Cat" />
              </div>
              <div
                className="services-list"
                onClick={() => handlePetType("other")}
              >
                <img src={Other} alt="Other" />
              </div>
            </div>
          </Col>
        </Row>

        <Container fluid className="container lg-ds">
          <Row>
            <Col lg={12}>
              <div className="services service-head">
                <h2>The most</h2>
                <p>Popular Dog Breeds</p>
                <h4 className="d-none d-lg-block">Popular Dog Breeds</h4>
              </div>
              <div className="services-dv-four adoption">
                {renderBreedCards(petData.dogBreeds, loading.dogs, "dog")}
              </div>
            </Col>
          </Row>
        </Container>

        <Container fluid className="container lg-ds">
          <Row>
            <Col lg={12}>
              <div className="services service-head">
                <h2>The most</h2>
                <p>Popular Cat Breeds</p>
                <h4 className="d-none d-lg-block">Popular Cat Breeds</h4>
              </div>
              <div className="services-dv-four adoption">
                {renderBreedCards(petData.catBreeds, loading.cats, "cat")}
              </div>
            </Col>
          </Row>
        </Container>

        {errorMessage && (
          <div className="error-message">
            <p>{errorMessage}</p>
          </div>
        )}

        <Testimonials />
        <PetAdoptionFaq />
      </Container>
    </div>
  );
};

export default PetAdoption;
