import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import PetAdoptionComp from "../../../component/Service";
import axios from 'axios';
import { ShimmerPostItem } from "react-shimmer-effects";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilterComponent from "../../../component/FilterComponent";
import Testimonials from "../../../component/Testimonials";
import FAQComponent from "../../../component/FAQComponent";
import { useLocation} from "react-router-dom";
import NotFound from "../../../component/NotFound";

const DogType = () => {
  const [petData, setPetData] = useState([]);
  const [loading,setLoading]  = useState(false);
  const location = useLocation();
  const [filters, setFilters] = useState({
    location: "",
    breed: "",
    priceRange: "",
    gender: "",
    age: "",
    coatType: "",
    color: "",
    size: "",
    temperament: "",
    healthAssurance: ""
  });
  
  useEffect(() => {
    const path = location.pathname?.split("+");
  
    const locationFilter = path[2]?.startsWith("india") ? "" : path[2]?.trim();
    
    const newFilters = {
      location: locationFilter || "",
      breed: path[3]?.trim() || "",
      gender: path[4]?.trim() || "",
      priceRange: path[5]?.trim() || "",
      color: path[6]?.trim() || "",
      age: path[7]?.trim() || "",
      coatType: path[8]?.trim() || "",
      size: path[9]?.trim() || "",
      temperament: path[10]?.trim() || "",
      healthAssurance: path[11]?.trim() || "",
    };

    setFilters(newFilters);  
    //setPetLocation(path[2]?.charAt(0).toUpperCase()+path[2]?.slice(1))

    getData(newFilters);
  }, [location]);
  
  const getData = (filters) => {
    setLoading(true);
    
    console.log(filters);
    const params = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        params.append(key, filters[key]);
      }
    });
    const url = `${process.env.REACT_APP_HOST}adoption?${params.toString()}&petType=cat`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.status === true) {
          setPetData(response.data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  };
  

  const placeholderItems = Array.from({ length: 4 });

  const handleFilter = (newFilters) => {
    const filters = Object.values(newFilters)
      .map((filter) => filter?.trim())
      .filter(Boolean);
  
    if (filters.length > 0) {
      filters[0] = `in+${filters[0]}`;
    }

    let filterPath = filters
      .join("+") 
      .replace(/\++/g, "+"); 
  
    filterPath = filterPath.replace(/\+$/, "");
    const url = `/buy-cats-online+${filterPath}`;  
    window.location.href = url;
  };




  return (
    <Container>

    <div className="top"/>
    <div className="top"/>
    <div className="top"/>
    <div className="top"/>
    <FilterComponent petType={"cat"} onFilter={handleFilter}  />


    <Container fluid className="container lg-ds">
      <Row>
        <Col lg={12} md={12} sm={12}>
           <div className="services service-head">
             <h2>Type Cat</h2>
             <p>Find Your Buddy!</p>
             <h4 id="hide">Find Your Buddy!</h4>
           </div>
         
        </Col>

        
      
      </Row>
    </Container>


    
    {!loading && petData.length === 0 &&
 <NotFound />
}
      <div className="adoption">

        
      {loading &&
      <div className="category-shimmer">

{placeholderItems.map((_, index) => (
        <ShimmerPostItem
          key={index} // Make sure to provide a unique key
          card
          title
          cta
          imageType="thumbnail"
          imageWidth={50}
          imageHeight={50}
          contentCenter
        />
      ))}
  
      </div>
     }
     
     {!loading && petData.length > 0  &&
     <>
      {petData.map((pet, index) => (
          <PetAdoptionComp
            key={index}
            petImage={pet.serviceImage}
            priceRange={`${pet.servicePrice}`}
            petName={pet.serviceName}
            address={pet.petBreed}
            gender={pet.petGender}
            dateOfBirth={pet.dateOfBirth}
            btnName="Whatsapp"
            petUserName={pet.petName}
            serviceCity={pet.serviceCity}
            serviceId={pet._id}
            petBreed={pet.petBreed}
        
          />
        ))}
     </>
}


      </div>
      {/* <Forum /> */}

      <Testimonials/>
      <FAQComponent/>
    </Container>
  );
}

export default DogType;
