import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";

const DogContent = ({ data }) => {
  return (
    <Container fluid className="py-4" style={{ backgroundColor: "#f8f9fa" }}>
      {data?.pricingBasedCoatColor?.length && (
        <>
          <Row className="mb-5">
            <Col>
              <h2 className="text-center fw-bold">
                {data?.pricingBasedCoatColorHead}
              </h2>
              <p className="fs-5 text-center text-muted">
                {data?.pricingBasedCoatColorContent}
              </p>
            </Col>
          </Row>
          <Row className="mb-5">
            {data?.pricingBasedCoatColor?.map((breed, index) => (
              <Col md={4} key={index} className="mb-4">
                <Card className="h-100 shadow-sm">
                  <Card.Body>
                    <Card.Title>
                      <h3 className="list-h3">
                        <strong>{breed.breed}</strong>
                      </h3>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        <strong>Price Range:</strong> {breed.price}
                      </p>
                    </Card.Text>
                    <Card.Text>
                      <p>{breed.description}</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}

      {data?.pricingChampionHead && (
        <Row className="mb-5">
          <Col>
            <h2 className=" text-center fw-bold">
              {data?.pricingChampionHead}
            </h2>
            <p className="fs-5 text-muted text-center">
              {data?.pricingChampionContent}
            </p>
            <ul variant="flush" className="fs-5 text-muted">
              {data?.pricingChampion?.map((fact) => (
                <li>
                  <p>
                    <strong>{fact.head}</strong> {fact.content}
                  </p>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      )}

      {data?.contentPara1Head && (
        <Row className="mb-4">
          <Col>
            <h2 className="text-center fw-bold">{data?.contentPara1Head}</h2>
            <p className="fs-5 text-muted text-center">
              {data?.contentPara1Cont}
            </p>
          </Col>
        </Row>
      )}

      {data?.contentPara2Head && (
        <Row className="mb-4">
          <Col>
            <h2 className="text-center fw-bold">{data?.contentPara2Head}</h2>
            <p className="fs-5 text-muted text-center">
              {data?.contentPara2Cont}
            </p>
          </Col>
        </Row>
      )}

      {data?.breedIntroHead && (
        <Row className="mb-5">
          <Col>
            <h2 className=" text-center fw-bold">{data?.breedIntroHead}</h2>
            <ul variant="flush" className="fs-5 text-muted">
              {data?.breedIntro?.map((fact) => (
                <li>
                  <p>
                    <strong>{fact.head}</strong> {fact.content}
                  </p>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      )}

      {data?.maintainance?.title && (
        <Row className="mb-5">
          <Col>
            <h2 className="text-center fw-bold">{data?.maintainance?.title}</h2>
            <p className="fs-5 text-muted text-center">
              {data?.maintainance?.description}
            </p>

            {data?.maintainance?.sections?.map((section, index) => (
              <div key={index} className="mb-4">
                <h3 className="fw-bold">{section.heading}</h3>
                <ul className="fs-5 text-muted">
                  {section.content?.map((block, idx) => (
                    <li key={idx}>
                      <p>
                        {block.children?.map((child) => child.text).join(" ")}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Col>
        </Row>
      )}

      {data?.training?.title && (
        <Row className="mb-5">
          <Col>
            <h2 className="text-center fw-bold">{data?.training?.title}</h2>
            <p className="fs-5 text-muted text-center">
              {data?.training?.description}
            </p>
            {data?.training?.sections?.map((section, index) => (
              <div key={index} className="mb-4">
                <h3 className="fw-bold">{section.heading}</h3>
                <ul className="fs-5 text-muted">
                  {section.content?.map((block, idx) => (
                    <li key={idx}>
                      <p>
                        {block.children?.map((child) => child.text).join(" ")}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Col>
        </Row>
      )}

      {data?.caringTips?.title && (
        <Row className="mb-5">
          <Col>
            <h2 className="text-center fw-bold">{data?.caringTips?.title}</h2>
            <p className="fs-5 text-muted text-center">
              {data?.caringTips?.description}
            </p>
            {data?.caringTips?.sections?.map((section, index) => (
              <div key={index} className="mb-4">
                <h3 className="fw-bold">{section.heading}</h3>
                <ul className="fs-5 text-muted">
                  {section.content?.map((block, idx) => (
                    <li key={idx}>
                      <p>
                        {block.children?.map((child) => child.text).join(" ")}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </Col>
        </Row>
      )}

      <Row className="mb-4">
        <Col>
          <h2 className="text-center fw-bold">Why Choose GoodFurs?</h2>
          <p className="text-center fs-5 text-muted">
            GoodFurs ensures a smooth and secure puppy-buying experience by
            offering:
          </p>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body>
              <Card.Title>
                <p>Certified Health and Purebred Documents</p>
              </Card.Title>
              <Card.Text>
                <p>
                  Each puppy comes with a health certificate, a vaccination
                  record, and a purebred certification (if applicable).
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body>
              <Card.Title>
                <p>Post-Purchase Vet Assistance</p>
              </Card.Title>
              <Card.Text>
                <p>
                  Enjoy 3 months of free vet assistance to answer questions on
                  health, diet, and puppy care.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body>
              <Card.Title>
                <p>EMI Options</p>
              </Card.Title>
              <Card.Text>
                <p>
                  With flexible payment options, getting the perfect puppy is
                  easier on the wallet.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {data?.puppyPricingGuid?.length && (
        <>
          <Row className="mb-5">
            <Col>
              <h2 className="text-center fw-bold">
                Puppy Pricing Guide for Popular Breeds in {data?.location}
              </h2>
              <p className="fs-5 text-center text-muted">
                Puppy prices vary based on breed, location, breeder quality, and
                age.
              </p>
            </Col>
          </Row>
          <Row>
            {data?.puppyPricingGuid?.map((breed, index) => (
              <Col md={4} key={index} className="mb-4">
                <Card className="h-100 shadow-sm">
                  <Card.Body>
                    <Card.Title>
                      <h3 className="list-h3">
                        <strong>{breed.breed}</strong>
                      </h3>
                    </Card.Title>
                    <Card.Text>
                      <p>
                        <strong>Price Range:</strong> {breed.price}
                      </p>
                    </Card.Text>
                    <Card.Text>
                      <p>{breed.description}</p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </>
      )}

      {data?.factorAffecting?.length && (
        <Row className="mb-5">
          <Col>
            <h2 className=" text-center fw-bold">
              Factors Affecting {data?.breed} Puppy Prices in {data?.location}
            </h2>
            <ul variant="flush" className="fs-5 text-muted">
              {data?.factorAffecting?.map((fact) => (
                <li>
                  <p>
                    <strong>{fact.head}</strong> {fact.content}
                  </p>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      )}

      {data?.topTenBreeds?.length && (
        <Row className="mb-5">
          <h2 className="text-center fw-bold">
            Top 10 Puppy Breeds and Their Prices
          </h2>
          <p className="fs-5 text-muted text-center">
            {data?.topTenBreedsCont}
          </p>
          <Col>
            <ListGroup variant="flush" className="fs-5 text-muted">
              {data?.topTenBreeds?.slice(0, 5).map((ele, index) => (
                <ListGroup.Item>
                  <h3 className="list-h3">
                    <strong>
                      {index + 1}. {ele.breed}
                    </strong>
                    <br />
                    Price: {ele.price}
                    <br />
                    Traits: {ele.traits}
                    <br />
                    Best For: {ele.bestFor}
                  </h3>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col>
            <ListGroup variant="flush" className="fs-5 text-muted">
              {data?.topTenBreeds?.slice(5, 11).map((ele, index) => (
                <ListGroup.Item>
                  <h3 className="list-h3">
                    <strong>
                      {index + 6}. {ele.breed}
                    </strong>
                    <br />
                    Price: {ele.price}
                    <br />
                    Traits: {ele.traits}
                    <br />
                    Best For: {ele.bestFor}
                  </h3>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      )}

      <Row className="mb-4">
        <Col>
          <h2 className="text-center fw-bold">
            How to Choose the Right Puppy Breed for Your Lifestyle
          </h2>
          <ListGroup variant="flush" className="fs-5 text-muted">
            <ListGroup.Item>
              <p>
                <strong>1. Living Space:</strong> Small breeds like Pugs and
                Dachshunds adapt well to apartments, while larger breeds need
                more room.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p>
                <strong>2. Activity Level:</strong> Active breeds like Labs and
                Beagles require daily exercise, while Pugs and Shih Tzus are
                more suited for lower activity levels.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p>
                <strong>3. Experience Level:</strong> First-time owners may
                prefer easy-going breeds like Labradors, while experienced
                owners can handle energetic breeds like Huskies.
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <p>
                <strong>4. Family Dynamics:</strong> Families with kids may
                benefit from gentle breeds like Golden Retrievers or Beagles.
              </p>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h2 className="text-center fw-bold ">
            Puppy Care Tips for New Owners
          </h2>
          <ul className="fs-5">
            <li>
              <p>
                <strong>Feeding: </strong>Establish a feeding schedule with
                age-appropriate food. Consult with a vet for dietary needs.
              </p>
            </li>
            <li>
              <p>
                <strong>Grooming:</strong> Regular grooming keeps your puppy’s
                coat healthy. Breeds like Shih Tzus require more frequent
                grooming.
              </p>
            </li>
            <li>
              <p>
                <strong>Training and Socialization:</strong> Start with basic
                obedience training and introduce them to various environments
                and people.
              </p>
            </li>
            <li>
              <p>
                <strong>Regular Health Checkups:</strong> Schedule routine vet
                checkups, especially during the first year. GoodFurs offers a
                3-month vet assistance plan for new puppy owners.
              </p>
            </li>
          </ul>{" "}
        </Col>
      </Row>

      <Row className="text-center">
        <Col>
          <h2 className="fw-bold">Conclusion</h2>
          <p className="text-muted fs-5">{data?.conclusion}</p>
        </Col>
      </Row>

      <Row className="mt-5 text-center">
        <Col>
          <h2 className="list-h3">Buy Dogs For Sale Online In Other Cities</h2>
          <p className="text-muted">
            {data?.locationLinks?.map((ele) => (
              <a className="m-1" href={ele.url}>
                {ele.linkName}
              </a>
            ))}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default DogContent;
